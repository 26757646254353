
import Vue, { PropType } from 'vue'
import { Connector, Project, ProjectType } from '@/types/main'
import SpinnerLoader from '../../../components/SpinnerLoader.vue'
import _ from 'lodash'

import { CONNECTOR_TYPES } from '../../../vars/general'
import BaseInputOutlined from '../../../components/inputs/BaseInputOutlined.vue'
import moment, { Moment } from 'moment'
import Select from '../../../components/inputs/Select.vue'
import { getKpiReportData, getKpiSchemeIdFilterValues } from '../../../api/chisai/GCB2'
import StaffBonusTable from '../../../components/chisai/GCB2/kpi/StaffBonusTable.vue'
import StaffActionsTable from '../../../components/chisai/GCB2/kpi/StaffActionsTable.vue'
import AttendanceTable from '../../../components/chisai/GCB2/kpi/AttendanceTable.vue'
import RecordsTable from '../../../components/chisai/GCB2/kpi/RecordsTable.vue'
import CustomActivatorSelect from '../../../components/inputs/CustomActivatorSelect.vue'

import api from '../../../api/api'
import {
    mapFilterFromObjectFormat,
    mapFilterToObjectFormat,
    getFilterListFromObjectFormat,
} from '../../../utils'
import { mapStores } from 'pinia'
import { useGcb2Store } from '../../../store/stores/gcb2/gcb2'
export default Vue.extend({
    components: {
        SpinnerLoader,
        BaseInputOutlined,
        CustomActivatorSelect,
        Select,
        StaffBonusTable,
        StaffActionsTable,
        AttendanceTable,
        RecordsTable,
    },

    data: () => ({
        loading: true as boolean,
        dates: [] as string[],
        dashboardData: {} as any,
        dateFormat: 'DD.MM.YYYY',
        projectType: null as ProjectType | null,
        dateMenu: false,
        filters: {
            users:{},
            activations:{}
        } as any,
        filtersValues: {} as any,
        userFilter: {},
        acticationFilter: {},
        productDisplayFilterValues: [
            { id: 1, name: 'Только услуги' },
            { id: 2, name: 'Услуги и товары, купленные с услугами' },
            { id: 3, name: 'Все услуги и товары' },
        ],
        communicactionChannelFilterValues: [
            { id: 'phone', name: 'Звонок' },
            { id: 'mess', name: 'Сообщение' },
            { id: 'no', name: 'Нет информации о типе связи' },
        ],
        items: [
            {
                name: 'name1',
                id: 1,
            },
            {
                name: 'name2',
                id: 2,
            },
        ],
        numRule: (v: any) => {
            if (!v.trim()) return true
            if (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) return true
            return 'Должно быть числом'
        },
    }),
    computed: {
        ...mapStores(useGcb2Store),
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        sameFilialFilterText(): string {
            return this.projectIsNetwork
                ? 'Учитывать записи и посещения только в филиал коммуникации'
                : 'Учитывать записи и посещения только в данный филиал'
        },
        projectIsNetwork(): boolean {
            return this.projectType === 'network'
        },
        projectIsBranch(): boolean {
            return this.projectType === 'branch'
        },
        GCB2Connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        dateRangeText(): string {
            const orderedDates = [this.minDate, this.maxDate]
            const formatedDates = orderedDates.map(date => moment(date).format(this.dateFormat))
            return formatedDates.join(' - ')
        },
        minDate(): Moment {
            if (this.dates.length === 1) {
                return moment(this.dates[0])
            }
            const date1 = moment(this.dates[0])
            const date2 = moment(this.dates[1])
            if (date1 < date2) {
                return date1
            } else {
                return date2
            }
        },
        maxDate(): Moment {
            if (this.dates.length === 1) {
                return moment(this.dates[0])
            }
            const date1 = moment(this.dates[0])
            const date2 = moment(this.dates[1])
            if (date1 > date2) {
                return date1
            } else {
                return date2
            }
        },
        tagsFilterValues(): any[] {
            return getFilterListFromObjectFormat(this.dashboardData.tag_list)
        },
        clientsFilterValues(): any[] {
            const currentFilters = this.dashboardData.raw_ca_visit_data.concat(this.dashboardData.raw_sa_data).concat(this.dashboardData.raw_ca_record_data)
            const filtersClientsNames = new Set<string>()
            for (let client of currentFilters){
                const userObj = {name:client.user_name, id:client.user_name}
                filtersClientsNames.add(JSON.stringify(userObj))
            }
            const filters = Array.from(filtersClientsNames).map(str => JSON.parse(str))
            this.updateUsersFilters(filters)
            return filters
        },
        activationsFilterValues(): any[] {
            const currentFilters = this.dashboardData.raw_ca_visit_data.concat(this.dashboardData.raw_sa_data).concat(this.dashboardData.raw_ca_record_data)
            const filtersActivationsNames = new Set<string>()
            for (let client of currentFilters) {
                const activationObj = { id: client.activation_name, name: client.activation_name }
                filtersActivationsNames.add(JSON.stringify(activationObj))
            }
            const filters = Array.from(filtersActivationsNames).map(str => JSON.parse(str))
            this.updateActivationsFilter(filters)
            return filters
        }
    },
    watch: {
        'filters.delayFromCommunication'(v) {
            if (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) {
                localStorage.setItem('kpi:filters.delayFromCommunication', v)
            }
        }
    },
    methods: {
        updateUsersFilters(currentUsers){
            for (let el of currentUsers){
                this.filters['users'][el.name] = {option:el.name, selected:false}
            }
        },
        updateActivationsFilter(currentActivations){
            for (let el of currentActivations){
                this.filters['activations'][el.name] = {option:el.name, selected:false}
            }
        },
        async getDataFromKPI(){
            const data = await getKpiReportData({
                projectId: this.project.id,
                filters: {
                    dateFrom: this.minDate.format('YYYY-MM-DD'),
                    dateTo: this.maxDate.format('YYYY-MM-DD'),
                    kpiSettingsId: this.filters.kpiSettingsId,
                    productsDisplay: this.filters.productsDisplay,
                    filialListComm: this.filters.filialListComm,
                    filialListAtt: this.filters.filialListAtt,
                    sameFilial: this.filters.sameFilial,
                    delayFromCommunication: Number(this.filters.delayFromCommunication),
                    typeOfCommList: mapFilterToObjectFormat(
                        this.filters.communicactionChannel,
                        this.communicactionChannelFilterValues
                    )
                },
            })
            const currentFilters = data.data['raw_ca_visit_data'].concat(data.data['raw_sa_data']).concat(data.data['raw_ca_record_data'])
            const filtersActivationsNames = new Set<string>()
            const filtersUsersNames = new Set<string>()
            for (let client of currentFilters) {
                const clientObj = { id: client.user_name, name: client.user_name }
                const activationObj = { id: client.activation_name, name: client.activation_name }
                filtersActivationsNames.add(JSON.stringify(activationObj))
                filtersUsersNames.add(JSON.stringify(clientObj))
            }
            const filtersAct = Array.from(filtersActivationsNames).map(str => JSON.parse(str))
            const filtersUsers = Array.from(filtersUsersNames).map(str => JSON.parse(str))

            return {activations: filtersAct, users: filtersUsers}
        },

        async setInitialData(removeFilters: boolean) {
            this.loading = true
            if (removeFilters) {
                await this.setInitialFilterValues()
                this.setDefaultDates()
            }

            const kpiData = await this.getDataFromKPI()
            
            for (let el of kpiData.users){
                this.filters.users[el.name] = {option:el.name, selected:true}
            }
            for (let el of kpiData.activations){
                this.filters.activations[el.name] = {option:el.name, selected:true}
            }

            if(this.filters.user.length != 0)
            for (let name in this.filters.users){
                this.filters.users[name] = {option:name, selected: this.filters.user.indexOf(name) == -1 ? false : true}
            }

            if(this.filters.activation.length != 0)
            for (let name in this.filters.activations){
                this.filters.activations[name] = {option:name, selected: this.filters.activation.indexOf(name) == -1 ? false : true}
            }

            const { data, error } = await getKpiReportData({
                projectId: this.project.id,
                filters: {
                    dateFrom: this.minDate.format('YYYY-MM-DD'),
                    dateTo: this.maxDate.format('YYYY-MM-DD'),
                    kpiSettingsId: this.filters.kpiSettingsId,
                    productsDisplay: this.filters.productsDisplay,
                    filialListComm: this.filters.filialListComm,
                    filialListAtt: this.filters.filialListAtt,
                    sameFilial: this.filters.sameFilial,
                    user_name_list: this.filters.users,
                    activation_name_list: this.filters.activations,
                    delayFromCommunication: Number(this.filters.delayFromCommunication),
                    tagList:
                        this.filters.tags && this.filters.tags.length && !removeFilters
                            ? mapFilterToObjectFormat(this.filters.tags, this.tagsFilterValues)
                            : undefined,
                    typeOfCommList: mapFilterToObjectFormat(
                        this.filters.communicactionChannel,
                        this.communicactionChannelFilterValues
                    )
                },
            })
            if (error) {
                this.$store.dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }
            this.dashboardData = data
            this.setDynamicFilterValues()
            this.loading = false
        },
        async setInitialFilterValues() {
            const responses = await Promise.all([
                getKpiSchemeIdFilterValues({
                    projectId: this.project.id,
                }),
                api.project.getNetworkProjects({ projectId: this.project.id }),
            ])
            this.filtersValues.kpiSettingsId = responses[0].data
            this.filtersValues.filialList = responses[1].data
            this.filters = Object.assign({}, this.filters, {
                kpiSettingsId: this.filtersValues.kpiSettingsId[0].id,
                productsDisplay: 1,
                sameFilial: this.projectIsBranch || this.projectIsNetwork ? false : undefined,
                filialListComm: this.projectIsNetwork
                    ? this.filtersValues.filialList.map((el: any) => el.id)
                    : undefined,
                filialListAtt: this.projectIsNetwork
                    ? this.filtersValues.filialList.map((el: any) => el.id)
                    : undefined,
                delayFromCommunication: localStorage.getItem('kpi:filters.delayFromCommunication') || '30',
                communicactionChannel: ['phone', 'mess', 'no'],
                user : [],
                activation : []

            })
        },
        //Динамические фильтры, т.е. те, которые фильтруют друг друга
        async setDynamicFilterValues() {
            this.filters = Object.assign({}, this.filters, {
                tags: mapFilterFromObjectFormat(this.dashboardData.tag_list),
            })
        },
        setDefaultDates() {
            const dateFormat = 'YYYY-MM-DD'
            const today = moment()
            const endDate = today.clone()
            const startDate = today.clone().subtract(7, 'days')
            this.dates = [startDate.format(dateFormat), endDate.format(dateFormat)]
        },
    },
    async created() {
        const [projectType] = await Promise.all([
            api.project.getType({ projectId: this.project.id }).then(res => res.data?.type!),
            this.gcb2Store.fetchCommunicationTags(this.project.id),
        ])
        this.projectType = projectType
        await this.setInitialData(true)
    },
    mounted() {},
})
